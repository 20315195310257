<!--
 * @Author: 钟澳鹏 1269372093@qq.com
 * @Date: 2023-03-21 11:02:09
 * @LastEditors: 钟澳鹏 1269372093@qq.com
 * @LastEditTime: 2023-03-22 10:40:04
 * @FilePath: \dalian\src\index\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div class="header">
      <img src="../assets/sd-logo.png" />
      <div class="tabs">
        <template v-for="(item, index) in tabs">
          <div
            :class="[active === item.id ? 'tab-active' : '']"
            :key="index"
            @click="tabsChange(item)"
          >
            {{ item.name }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderContent",
  data() {
    return {
      active: "HomeContent",
      tabs: [
        { id: "HomeContent", name: "网站首页" },
        { id: "ProductContent", name: "经典案例" },
        { id: "NewsContent", name: "新闻动态" },
        { id: "AboutContent", name: "关于善贷" },
      ],
    };
  },
  methods: {
    tabsChange(item) {
      this.active = item.id;
      this.$emit("change", item.id);
    },
  },
};
</script>

<style scoped>
.header {
  height: 90px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
img {
  /* width: 351px ; */
  height: 51px;
}
.tabs {
  width: 615px;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  color: #666666;
  margin-left: 167px;
}
.tabs > div {
  cursor: pointer;
}
.tab-active {
  color: #36a9ff;
  font-weight: bold;
}
</style>
