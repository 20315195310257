<template>
  <div class="about-content">
    <img src="../assets/sd-banner.png" />
    <img
      src="../assets/sd-about.png"
      style="padding: 100px 300px; box-sizing: border-box"
    />
    <!-- <div class="container">
      <div class="title-wrap">
        <div class="title">关于佳钰</div>
        <div class="text">
          您现在的位置：<span>首页</span>-关于佳钰
        </div>
      </div>
      <div class="content">
        <div>
          大连佳钰重型机械有限公司于2007年07月05日经大连保税区市场监督管理局批准成立，注册号912102426611326840，注册资金伍仟万元整。
          公司经营范围：重型机械、冶金机械设备及配件制造、加工、安装；机电产品（轿车除外）、化工产品（危险化学品除外）、汽车配件、金属材料、建筑材料销售；货物进出口、
          技术进出口（法律、行政法规禁止的项目除外；法律、行政法规限制的项目，取得许可后方可经营)。公司主要以国内一般贸易销售电解铜为主，为客户提供充沛的货源及
          优质、便捷、高效的配送服务。
        </div>
        <div>
          大连佳钰重型机械有限公司以有色金属以电解铜为主。电解铜是与人类关系非常密切的有色金属，被广泛地应用于电气、轻工、机械制造、建筑工业、国防工业等领域，
          在我国有色金属材料的消费中仅次于铝。中国是全球电解铜第一大生产与消费国，但中国铜资源极度匮乏，自给率不足30%，且呈现逐年扩大之势，因此也是第一大铜进口国，
          传统进口大多来自南美、蒙古、非洲和澳大利亚等地。铜良好的天然属性是其它工业品和农产品所无可比拟的，不仅具有良好的自然属性，而且具有很强的金融属性与保值功能，
          其作为战略物资，历来被世界各国作为仓单交易和库存融资的首选品种。而铜融资性贸易的存在，也正是其金融属性的外在表现形式之一。有鉴于此，铜进口通常都被视为中国经济增长速度的标杆之一，
          全球许多投资行都直接或间接地参与中国市场铜交易，并通过具有现货背景的大型贸易商进行融资操作。
        </div>
        <div>
          企业发展，人才先行。公司建立了一支业务精通，操作能力强的骨干队伍。我们秉承“质量为先 信誉为重 服务至上”的宗旨，以“最合理的价格、最完善的服务，提供最优的产品”的服务方针为指导，
          凭借过硬的产品质量及优质的销售服务，赢得了广大客户的认同及赞许，呈现出高速发展的势头。自公司成立以来，与多家企业建立了密切的合作关系。 产品的采购销售结算多以现金，承兑为主，
          以月为结算周期。公司的发展离不开客户的信赖与支持，我们时刻以做强、做大中国商贸事业为己任严格要求自己，致力于代理及经营优秀品牌产品，期待成为客户信赖的伙伴，
          在关键的时刻为客户提供优质全面的解决方案及最完善的产品服务，与客户精诚合作，共创未来!
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "AboutContent",
};
</script>

<style scoped>
img {
  width: 100%;
}
.about-content {
  min-height: 100%;
}
.container {
  width: 1200px;
  background: white;
  padding: 0 24px;
  margin: 40px auto 60px;
  box-sizing: border-box;
}
.banner1 {
  width: 100%;
  height: 302px;
}
.about-content > .container > .title-wrap {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebebeb;
  height: 63px;
}
.about-content > .container > .title-wrap > .title {
  font-size: 26px;
  font-family: Alibaba PuHuiTi;
  font-weight: bold;
  color: #d80f20;
  flex: 1;
}
.about-content > .container > .title-wrap > .text {
  font-size: 16px;
  color: #999999;
}
.about-content > .container > .title-wrap > .text > span {
  font-size: 16px;
  color: #666666;
}
.about-content > .container > .content {
  padding: 38px 0;
  font-size: 16px;
  font-family: Alibaba PuHuiTi;
  font-weight: 400;
  color: #666666;
}
</style>
